<template>
  <PublicApply :post="post"></PublicApply>
</template>
<script>
import PublicApply from './PublicApply'
export default {
  name: 'BasicLever',
  data () {
    return {
      post: {
        service_type: '预约-基层法律服务',
        type: '基层法律服务所',
        service_name: '基层法律服务预约办理',
        info: '' +
          '<p>1、申请人保证以上本人填写的情况及提供的资料真实有效。<p>' +
          '<p>2、申请人应如实陈述案件事实及相关情况，及时全面提供有关证明和证据材料。</p>' +
          '<p>3、申请人应协助调查法律事项的事实。</p>' +
          '<p>4、基层法律服务可以依照法律规定收取相关费用，但不得因此得利。</p>' +
          '<p>5、有下列情形之一的，基层法律服务所或者法律服务人员可以申请终止法律服务∶①因申请人不履行义务严重影响案件进程的的;' +
          '<br>②申请人提供虚假证明或者要求违法提供服务的;<br>③在法律服务过程中发现申请人有其他不符合法律服务行为的。</p>'
      }
    }
  },
  components: {
    PublicApply
  }
}
</script>
<style scoped >
</style>
